<template>
  <Layout :tituloPagina="'Clientes | ' + (modo == 'nuevo'? 'Nuevo ': 'Edición de ') + 'cliente'">
    <div class="row">
      <div class="col-xxl-3" v-show="foto_cliente">
        <div class="user-sidebar">
          <div class="card">
            <div class="card-body p-0">
              <div class="user-profile-img">
                <img
                  src="@/assets/images/pattern-bg.jpg"
                  class="profile-img profile-foreground-img rounded-top"
                  style="height: 120px"
                />
                <div class="overlay-content rounded-top">
                  <div>
                    <div class="user-nav p-3">
                      <div class="d-flex justify-content-end">
                        <div class="dropdown">
                          <a
                            class="dropdown-toggle"
                            href="javascript: void(0);"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <eva-icon
                              name="more-horizontal-outline"
                              data-eva-width="20"
                              data-eva-height="20"
                              class="fill-white"
                            ></eva-icon>
                          </a>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <button class="dropdown-item" @click="seleccionarFoto()">
                                <eva-icon name="repeat" class="text-primary"></eva-icon>
                                {{foto == null ? 'Seleccionar ' : 'Cambiar '}} imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="eliminarFoto()">
                                <eva-icon name="trash-2-outline" class="text-danger"></eva-icon>
                                Eliminar imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="visibleImagen = true">
                                <eva-icon name="eye-outline" class="text-info"></eva-icon>
                                Ver imagen completa
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-n5 position-relative">
                <div class="text-center">
                  <div v-show="foto == null">
                    <img
                      :src="clienteIcon"
                      alt="Foto del cliente"
                      class="avatar-xl rounded-circle img-thumbnail cursor"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                  </div>
                  <div v-if="foto != null">
                    <img
                      :src="foto"
                      class="avatar-xl rounded-circle img-thumbnail cursor"
                      alt="Foto del cliente"
                      title="Ver imagen completa"
                      @click="visibleImagen = true"
                    />
                    <vue-easy-lightbox
                      :visible="visibleImagen"
                      :imgs="foto_visor"
                      @hide="visibleImagen = false"
                    ></vue-easy-lightbox>
                  </div>
                  <div class="mt-3">
                    <h5 class="mb-1">
                      {{ cliente.nombre != '' ? cliente.nombre : 'Nombre(s)' }} 
                      {{ cliente.apellido_paterno != '' ? cliente.apellido_paterno : 'Apellido' }} 
                      {{ cliente.apellido_materno != '' ? cliente.apellido_materno : 'Apellido' }}
                    </h5>

                    <div class="mt-3" v-if="cliente.id != null">
                      <h4 class="mb-1">
                        Número de cliente
                      </h4>
                      <h3 class="mb-1">
                        <strong 
                          style="font-size: 36px !important;"
                          :class="{
                            'text-success': cliente.activo,
                            'text-danger': !cliente.activo
                          }"
                        >
                          {{ cliente.numero }}
                        </strong>
                      </h3>
                    </div>
                    <div class="mt-4">
                      <h4>{{cliente.empresa ? 'Empresa' : 'Persona'}}</h4>
                      <p>
                        <strong>RFC: </strong>
                        {{ cliente.rfc != '' && cliente.rfc != null ? cliente.rfc : 'XXXX0000000000' }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-3 mt-3"></div>
            </div>
          </div>
        </div>
      </div>
      <div :class="foto_cliente ? 'col-xxl-9':'col-xxl-12'">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Datos del cliente
              {{ modo == 'nuevo' ? '' : cliente.numero }}
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      onclick="return false;"
                      v-show="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row" :class="cliente.id != null ? 'mb-4' : ''">
              <div class="col-md-3" v-if="cliente.id != null">
                <label>Número de cliente</label>
                <input
                  type="number"
                  ref="numero_cliente"
                  v-model="cliente.numero"
                  @change="cliente.numero < 0 ? (cliente.numero = 0) : null"
                  class="form-control text-right"
                />
              </div>
              <!-- <div class="col-md-3">
                <label>Activar timbrado de facturas</label>
                <div class="form-check form-switch form-switch-lg">
                  <input
                    class="form-check-input"
                    type="checkbox" id="timbrado-facturas"
                    checked=""
                  />
                  <label class="form-check-label" for="timbrado-facturas">
                    Timbrado
                  </label>
                </div>
              </div> -->
            </div>
            
            <div class="row">
              <div class="col-md-3" v-show="tieneAlias('Nombre')">
                <label>{{cargarAliasCampo('Nombre')}}</label>
                <input
                  class="form-control"
                  ref="nombre_cliente"
                  v-model="cliente.nombre"
                />
              </div>
              <div class="col-md-3" v-show="tieneAlias('Apellido paterno')">
                <label>{{cargarAliasCampo('Apellido paterno')}}</label>
                <input
                  class="form-control"
                  ref="apellido_paterno"
                  v-model="cliente.apellido_paterno"
                />
              </div>
              <div class="col-md-3" v-show="tieneAlias('Apellido materno')">
                <label>{{cargarAliasCampo('Apellido materno')}}</label>
                <input
                  class="form-control"
                  name="apellido_materno"
                  v-model="cliente.apellido_materno"
                />
              </div>
              <div class="col-md-3" v-show="tieneAlias('RFC')">
                <label>{{cargarAliasCampo('RFC')}}</label>
                <input class="form-control" name="rfc" v-model="cliente.rfc" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-3" v-show="tieneAlias('Sexo')">
                <label>{{cargarAliasCampo('Sexo')}}</label>
                <select class="form-select" name="sexo" v-model="cliente.sexo">
                  <option value="hombre">Hombre</option>
                  <option value="mujer">Mujer</option>
                </select>
              </div>
              <div class="col-md-3" v-show="tieneAlias('Tipo de cliente')">
                <label>{{cargarAliasCampo('Tipo de cliente')}}</label>
                <select
                  class="form-select"
                  name="empresa"
                  v-model="cliente.empresa"
                >
                  <option :value="0">Persona</option>
                  <option :value="1">Empresa</option>
                </select>
              </div>
              <div class="col-md-3" v-show="tieneAlias('Estado')">
                <label>{{cargarAliasCampo('Estado')}}</label> <br />
                <span
                  class="badge"
                  :class="{
                    'bg-success': cliente.activo,
                    'bg-danger': !cliente.activo
                  }"
                >
                  {{ cliente.activo ? 'Activo' : 'Retirado' }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" v-show="tieneAlias('Nota')">
                <label>{{cargarAliasCampo('Nota')}}</label>
                <textarea
                  style="height:108px"
                  class="form-control"
                  name="nota"
                  v-model="cliente.nota"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Dirección del cliente
          {{ modo == 'nuevo' ? '' : cliente.numero }}
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  onclick="return false;"
                  v-show="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3" v-show="tieneAlias('Calle')">
            <label>{{cargarAliasCampo('Calle')}}</label>
            <input
              class="form-control"
              name="calle"
              v-if="tieneOpciones('Calle')"
              v-model="cliente.calle"
            />
            <select
              class="form-select"
              name="calle"
              v-if="!tieneOpciones('Calle')"
              v-model="cliente.calle"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Calle')"
                :key="`opcion-calle-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-2" v-show="tieneAlias('N° ext')">
            <label>{{cargarAliasCampo('N° ext')}}</label>
            <input
              class="form-control"
              name="numero_exterior"
              v-if="tieneOpciones('N° ext')"
              v-model="cliente.numero_exterior"
            />
            <select
              class="form-select"
              name="numero_exterior"
              v-if="!tieneOpciones('N° ext')"
              v-model="cliente.numero_exterior"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('N° ext')"
                :key="`opcion-numero-exterior-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-2" v-show="tieneAlias('N° int')">
            <label>{{cargarAliasCampo('N° int')}}</label>
            <input
              class="form-control"
              name="numero_interior"
              v-if="tieneOpciones('N° int')"
              v-model="cliente.numero_interior"
            />
            <select
              class="form-select"
              name="numero_interior"
              v-if="!tieneOpciones('N° int')"
              v-model="cliente.numero_interior"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('N° int')"
                :key="`opcion-numero-interior-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" v-show="tieneAlias('Colonia')">
            <label>{{cargarAliasCampo('Colonia')}}</label>
            <input
              class="form-control"
              name="colonia"
              v-if="tieneOpciones('Colonia')"
              v-model="cliente.colonia"
            />
            <select
              class="form-select"
              name="colonia"
              v-if="!tieneOpciones('Colonia')"
              v-model="cliente.colonia"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Colonia')"
                :key="`opcion-colonia-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3" v-show="tieneAlias('Delegación o Municipio')">
            <label>{{cargarAliasCampo('Delegación o Municipio')}}</label>
            <input
              class="form-control"
              name="municipio"
              v-if="tieneOpciones('Delegación o Municipio')"
              v-model="cliente.municipio"
            />
            <select
              class="form-select"
              name="municipio"
              v-if="!tieneOpciones('Delegación o Municipio')"
              v-model="cliente.municipio"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Delegación o Municipio')"
                :key="`opcion-minicipio-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3" v-show="tieneAlias('Código postal')">
            <label>{{cargarAliasCampo('Código postal')}}</label>
            <input
              class="form-control"
              name="codigo_postal"
              v-if="tieneOpciones('Código postal')"
              v-model="cliente.codigo_postal"
            />
            <select
              class="form-select"
              name="codigo_postal"
              v-if="!tieneOpciones('Código postal')"
              v-model="cliente.codigo_postal"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Código postal')"
                :key="`opcion-codigo-postal-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" v-show="tieneAlias('Estado de residencia')">
            <label>{{cargarAliasCampo('Estado de residencia')}}</label>
            <input
              class="form-control"
              name="estado_residencia"
              v-if="tieneOpciones('Estado de residencia')"
              v-model="cliente.estado_residencia"
            />
            <select
              class="form-select"
              name="estado_residencia"
              v-if="!tieneOpciones('Estado de residencia')"
              v-model="cliente.estado_residencia"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Estado de residencia')"
                :key="`opcion-estado-residencia-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3" v-show="tieneAlias('País')">
            <label>{{cargarAliasCampo('País')}}</label>
            <input
              class="form-control"
              name="pais"
              v-if="tieneOpciones('País')"
              v-model="cliente.pais"
            />
            <select
              class="form-select"
              name="pais"
              v-if="!tieneOpciones('País')"
              v-model="cliente.pais"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('País')"
                :key="`opcion-pais-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
        </div>

        <br>
        <h5>
          Datos de contacto
        </h5>
        <hr>

        <div class="row">
          <div class="col-lg-4 col-md-5" v-show="tieneAlias('Teléfonos')">
            <label>{{cargarAliasCampo('Teléfonos')}}</label>
            <table>
              <tr>
                <td>
                  <select class="form-select" v-model="tipoTelefono">
                    <option value="celular">Celular</option>
                    <option value="casa">Casa</option>
                    <option value="trabajo">Trabajo</option>
                    <option value="otro">Otro</option>
                  </select>
                </td>
                <td style="width: 80px">
                  <input type="text" v-model="prefijo" title="Prefijo telefónico"
                    class="form-control" placeholder="+52" onkeypress="return check(event, 'telefono')">
                </td>
                <td>
                  <input
                    ref="numeroTelefonico"
                    type="text"
                    class="form-control"
                    v-model="numeroTelefono"
                    v-on:keyup.enter="agregarTelefono()"
                    onkeypress="return check(event, 'telefono')"
                    placeholder="55 1234 5678"
                  />
                </td>
                <td>
                  <button
                      class="btn btn-light"
                      @click="agregarTelefono()"
                      type="button"
                    >
                    <i class="mdi mdi-plus"></i>
                  </button>
                </td>
              </tr>
            </table>
            <div class="table-responsive" style="min-height: 120px">
              <table id="tbl-telefonos" class="table table-hover">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Prefijo</th>
                    <th>Número</th>
                    <th>
                      <i class="fa fa-minus"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(telefono, index) in cliente.telefonos"
                    :key="telefono.id"
                  >
                    <td>{{ telefono.tipo }}</td>
                    <td>
                      <input 
                        type="text" v-model="telefono.prefijo"
                        class="prefijo"
                        :class="modoTemplate == 'dark' ? 'text-gris' : 'text-dark'"
                      />
                    </td>
                    <td>
                      <input
                        type="text" v-model="telefono.numero"
                        class="telefono" :class="modoTemplate == 'dark' ? 'text-gris' : 'text-dark'"
                        onkeypress="return check(event, 'telefono')"
                      />
                    </td>
                    <td>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="eliminarTelefono(index)"
                      >
                        <i class="mdi mdi-minus"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-lg-3 col-md-5">
            <div v-show="tieneAlias('Correo electrónico')">
              <label>{{cargarAliasCampo('Correo electrónico')}}</label>
              <input
                type="text"
                class="form-control"
                v-model="cliente.correo"
                placeholder="correo@empresa.com"
              />
              <br>
            </div>
            <div v-show="tieneAlias('Messenger')">
              <label for="messenger">{{cargarAliasCampo('Messenger')}}</label>
              <div class="input-group">
                <div class="input-group-text">
                  {{cliente.messenger != null && cliente.messenger.substring(0,4) == 'http'? 'URL': '@'}}
                </div>
                <input
                  type="text"
                  name="messenger"
                  class="form-control"
                  v-model="cliente.messenger"
                  placeholder="Nombre de usuario o ID de Facebook Messenger"
                  autocomplete="off"
                  maxlength="250"
                />
              </div>
              <br>
            </div>
          </div>
          <br>
          <div class="col-lg-4 col-md-6">
            <div v-show="tieneAlias('URL 1')">
              <label for="url1">{{cargarAliasCampo('URL 1')}}</label>
              <input
                  type="text"
                  name="url1"
                  class="form-control"
                  v-model="cliente.url1"
                  placeholder="URL1 personalizable"
                  autocomplete="off"
                  maxlength="500"
                />
              <br>
            </div>
            <div v-show="tieneAlias('URL 2')">
              <label for="url2">{{cargarAliasCampo('URL 2')}}</label>
              <input
                  type="text"
                  name="url2"
                  class="form-control"
                  v-model="cliente.url2"
                  placeholder="URL2 personalizable"
                  autocomplete="off"
                  maxlength="500"
                />
            </div>
          </div>
        </div>
        <br>
        <h5 v-show="
          tieneAlias('Dato auxiliar 1') ||
          tieneAlias('Dato auxiliar 2') ||
          tieneAlias('Dato auxiliar 3') ||
          tieneAlias('Dato auxiliar 4')
        ">Datos auxiliares del cliente</h5>
        <hr v-show="
        tieneAlias('Dato auxiliar 1') ||
        tieneAlias('Dato auxiliar 2') ||
        tieneAlias('Dato auxiliar 3') ||
        tieneAlias('Dato auxiliar 4')
      ">
        <div class="row">
          <div class="col-sm-3" v-show="tieneAlias('Dato auxiliar 1')">
            <label for="url2">{{cargarAliasCampo('Dato auxiliar 1')}}</label>
            <input
              type="text"
              name="datoaux1"
              class="form-control"
              v-model="cliente.datoaux1"
              placeholder="Dato auxiliar 1"
              autocomplete="off"
              maxlength="500"
            />
          </div>
          <div class="col-sm-3" v-show="tieneAlias('Dato auxiliar 2')">
            <label for="url2">{{cargarAliasCampo('Dato auxiliar 2')}}</label>
            <input
              type="text"
              name="datoaux2"
              class="form-control"
              v-model="cliente.datoaux2"
              placeholder="Dato auxiliar 2"
              autocomplete="off"
              maxlength="500"
            />
          </div>
          <div class="col-sm-3" v-show="tieneAlias('Dato auxiliar 3')">
            <label for="url2">{{cargarAliasCampo('Dato auxiliar 3')}}</label>
            <input
              type="text"
              name="datoaux3"
              class="form-control"
              v-model="cliente.datoaux3"
              placeholder="Dato auxiliar 3"
              autocomplete="off"
              maxlength="500"
            />
          </div>
          <div class="col-sm-3" v-show="tieneAlias('Dato auxiliar 4')">
            <label for="url2">{{cargarAliasCampo('Dato auxiliar 4')}}</label>
            <input
              type="text"
              name="datoaux4"
              class="form-control"
              v-model="cliente.datoaux4"
              placeholder="Dato auxiliar 4"
              autocomplete="off"
              maxlength="500"
            />
          </div>
        </div>

        <br>
        <h5 v-show="tieneAlias('Panel de cliente')">
          {{cargarAliasCampo('Panel de cliente')}}
        </h5>
        <hr v-show="tieneAlias('Panel de cliente')">

        <div class="row">
          <div class="col-lg-4 col-md-6" v-show="tieneAlias('Panel de cliente')">
            <div class="input-group">
              <div class="input-group-text">
                Número de cliente
              </div>
              <input
                class="form-control"
                name="numerocliente"
                :value="`${cliente.prefnumcli}-${cliente.numero}`"
                readonly
                placeholder="pref-001"
              />
            </div>
            <br>
            <div class="input-group">
              <div class="input-group-text">
                Clave de acceso
              </div>
              <input
                class="form-control"
                name="clave"
                :placeholder="mostrar_clave ? 'Password' : '******'"
                :type="mostrar_clave ? 'text' : 'password'"
                v-model="cliente.clave_sin_encriptar"
              />
              <button
                class="btn btn-light"
                @click="mostrar_clave = !mostrar_clave"
              >
                <i
                  class="fa"
                  :class="{
                    'fa-eye': mostrar_clave,
                    'fa-eye-slash': !mostrar_clave
                  }"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3" v-show="modo == 'nuevo'">
        <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
          <input
            v-model="bandera_nuevo_contrato"
            class="form-check-input"
            type="checkbox"
            id="contrato"
          />
          <label class="form-check-label" for="contrato">Crear contrato</label>
        </div>
      </div>
    </div>

    <div class="card" v-show="modo == 'nuevo' && bandera_nuevo_contrato">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">Contrato</h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  onclick="return false;"
                  v-show="!contrato.cancelado && !bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <label>ID de contrato físico <sup>* Opcional</sup></label>
                <input
                  type="text"
                  v-model="contrato.id_contrato_fisico"
                  class="form-control"
                  placeholder="1234"
                  :disabled="contrato.cancelado == 1"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Fecha de instalación</label>
                <Datepicker
                  ref="fecha_instalacion"
                  v-model="contrato.fecha_instalacion"
                  placeholder="dd/mm/aaaa"
                  :class="'form-control bg-white'"
                  :inputFormat="'dd/MM/yyyy'"
                  :disabled="contrato.cancelado == 1"
                />
              </div>
              <div class="col-md-6">
                <label>Termino de contrato <small>(opcional)</small> </label>
                <Datepicker
                  ref="fecha_vencimiento"
                  v-model="contrato.fecha_vencimiento"
                  placeholder="dd/mm/aaaa"
                  :class="'form-control bg-white'"
                  :inputFormat="'dd/MM/yyyy'"
                  :disabled="contrato.cancelado == 1"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label>Nota</label>
            <textarea
              rows="4"
              class="form-control"
              v-model="contrato.nota"
              placeholder="Nota del contrato"
              :disabled="contrato.cancelado == 1"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-show="modo == 'nuevo' && bandera_nuevo_contrato">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">Servicio de internet</h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  onclick="return false;"
                  v-show="!contrato.cancelado && !bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <label>Servicio</label> <br />
            <select
              class="form-select"
              ref="id_servicio"
              v-model="contrato.id_servicio"
              @change="actualizarServicioSeleccionado()"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="servicio in servicios"
                :key="servicio.id"
                :value="servicio.id"
              >
                {{ servicio.nombre }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Precio</label>
            <div class="input-group">
              <span class="input-group-text">
                {{ monedaSistema.simbolo }}
              </span>
              <input
                type="text"
                class="form-control text-right"
                v-model="contrato.precio_servicio"
                placeholder="0"
                readonly
                :disabled="contrato.cancelado == 1"
              />
              <span class="input-group-text">
                {{ monedaSistema.codigo }}
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <label>DW / UP</label>
            <input
              type="text"
              v-model="contrato.carga_descarga_servicio"
              class="form-control"
              placeholder="1024k/512k"
              readonly
              :disabled="contrato.cancelado == 1"
            />
          </div>

          <div class="col-md-3">
            <label>Cargos por morosidad</label>
            <div class="form-check form-switch form-switch-lg mb-3">
              <input
                v-model="contrato.cargo_morosidad"
                class="form-check-input"
                type="checkbox"
                id="cargosPorMorosidad"
              />
              <label class="form-check-label" for="cargosPorMorosidad">
                <!-- {{ contrato.cargo_morosidad ? 'Aplicar' : 'No aplicar' }} -->
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label>Tipo de renta</label>
            <select
              class="form-select"
              v-model="contrato.id_tipo_renta"
              @change="tipoRentaActualizada()"
              :disabled="contrato.cancelado == 1"
            >
              <option value="1">Mensual fija</option>
              <option value="2">Mensual variable</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Modalidad del servicio</label>
            <select
              class="form-select"
              v-model="contrato.prepago"
              :disabled="
                contrato.id_tipo_renta == 2 || contrato.cancelado == 1
              "
            >
              <option value="1">Prepago</option>
              <option value="0">Pospago</option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Modo para generar facturas</label>
            <select
              class="form-select"
              v-model="contrato.modo_generar_facturas"
              :disabled="
                contrato.id_tipo_renta == 2 || contrato.cancelado == 1
              "
            >
              <option :value="1">
                1. Genera las facturas cuando las previas han sido pagadas y 
                toma de referencia el fin del periodo del contrato.
              </option>
              <option :value="2">
                2. Un día específico de cada mes en función del día de pago, 
                sin importar si las facturas previas han sido pagadas.
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-show="modo == 'nuevo' && bandera_nuevo_contrato">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">Periodos del servicio de internet</h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  onclick="return false;"
                  v-show="!contrato.cancelado && !bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <label>Dia inicial del periodo</label>
            <select
              v-model="contrato.dia_inicio_periodo"
              class="form-select"
              :disabled="contrato.cancelado == 1"
              v-if="contrato.id_tipo_renta == 1"
            >
              <option v-for="n in 31" :key="n" :value="n">
                Día {{ n }} del mes
              </option>
            </select>

            <div v-if="contrato.id_tipo_renta == 2">
              <input
                type="text"
                class="form-control"
                value="Indefinido"
                readonly
              />
            </div>
          </div>
          <span class="d-block d-lg-none">
            <div class="col-md-6"></div>
          </span>
          <div class="col-lg-3 col-md-6">
            <label>Inicio del periodo</label>
            <Datepicker
              ref="inicio_periodo"
              v-model="contrato.inicio_periodo"
              placeholder="dd/mm/aaaa"
              :class="'form-control bg-white'"
              :inputFormat="'dd/MM/yyyy'"
              :disabled="modo != 'nuevo'"
            />
          </div>
          <div class="col-lg-3 col-md-6">
            <label>Fin del periodo</label>
            <Datepicker
              ref="fin_periodo"
              v-model="contrato.fin_periodo"
              placeholder="dd/mm/aaaa"
              :class="'form-control bg-white'"
              :inputFormat="'dd/MM/yyyy'"
              :disabled="modo != 'nuevo'"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6">
            <label>Día de pago</label>
            <div v-if="contrato.id_tipo_renta == 1">
              <select
                class="form-select"
                v-model="contrato.dia_pago"
                :disabled="contrato.cancelado == 1"
              >
                <option :value="0">
                  {{'Mismo día del fin de periodo'}}
                </option>
                <option v-for="n in 27" :key="n" :value="n">
                  {{ n }} 
                  {{`día${n>1?'s':''} antes del fin de periodo`}}
                </option>
              </select>
            </div>
            <div v-if="contrato.id_tipo_renta == 2">
              <input
                type="text"
                class="form-control"
                value="Día del fin de periodo"
                readonly
              />
            </div>
            <input type="text"
              :value="fecha_pago"
              class="form-control"
              readonly
            />
          </div>
          <div class="col-lg-3 col-md-6">
            <label>Día de corte del servicio</label>
            <select
              class="form-select"
              v-model="contrato.n_dias_corte"
              :disabled="contrato.cancelado == 1"
              v-if="contrato.id_tipo_renta == 1"
            >
              <option value="0">El mismo día de pago</option>
              <option v-for="n in 15" :key="n" :value="n">
                {{ n }} día{{ n > 1 ? 's' : '' }} después del día de pago
              </option>
            </select>
            <div v-if="contrato.id_tipo_renta == 2">
              <input
                type="text"
                class="form-control"
                value="Día del fin de periodo"
                readonly
              />
            </div>
            <input type="text"
              :value="fecha_corte_servicio"
              class="form-control"
              readonly
            />
          </div>
          <div class="col-lg-3 col-md-6">
            <label>Crear factura</label>
            <select
              class="form-select"
              v-model="contrato.n_dias_generar_factura"
              :disabled="contrato.cancelado == 1"
            >
              <option :value="0">
                <template v-if="contrato.id_tipo_renta == 1">
                  Generar el mismo día de pago
                </template>
                <template v-if="contrato.id_tipo_renta == 2">
                  Generar al final del periodo
                </template>
              </option>
              <option v-for="n in 15" :key="n" :value="n">
                {{ n }} día{{ n > 1 ? 's' : '' }}
                <template v-if="contrato.id_tipo_renta == 1">
                  antes del día de pago
                </template>
                <template v-if="contrato.id_tipo_renta == 2">
                  antes del final del periodo
                </template>
              </option>
            </select>
            <input type="text"
              :value="fecha_generar_nueva_factura"
              class="form-control"
              readonly
            />
          </div>

          <div class="col-lg-3 col-md-6">
            <label>Notificacion por correo</label>
            <div class="form-check form-switch form-switch-lg mb-3">
              <input
                v-model="contrato.notificacion_correo"
                class="form-check-input"
                type="checkbox"
                id="notificacionPorCorreo"
              />
              <label class="form-check-label" for="notificacionPorCorreo">
                <!-- {{ contrato.notificacion_correo ? 'Aplicar' : 'No aplicar' }} -->
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-show="modo == 'nuevo' && bandera_nuevo_contrato">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">CPE <small>Equipo local del cliente</small></h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      onclick="return false;"
                      v-show="!contrato.cancelado && !bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <router-link
                  :to="{
                    name: 'edicionCpe',
                    params: { id: contrato.id_cpe }
                  }"
                  v-if="cpeSeleccionado.id != null"
                >
                  <img
                    :src="
                      `${API}/cpes/${cpeSeleccionado.id}/imagen?tipo_foto=150x150&_tk=${tk}`
                    "
                    alt="imagen del cpe"
                    v-if="cpeSeleccionado.id != null"
                    class="img-fluid img-thumbnail rounded"
                  />
                </router-link>
                <img
                  :src="
                    `${API}/cpes/modelos/${cpeSeleccionado.id_modelo}/imagen?tipo_foto=150x150&_tk=${tk}`
                  "
                  alt="imagen del cpe"
                  v-if="cpeSeleccionado.id == null && cpeSeleccionado.id_modelo != null"
                  class="img-fluid img-thumbnail rounded"
                />
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <label>Selección de CPE</label>
                    <select
                      class="form-select"
                      name="id_cpe"
                      ref="id_cpe"
                      v-model="contrato.id_cpe"
                      @change="actualizarCpeSeleccionado()"
                    >
                      <option :value="null">Ninguno</option>
                      <option :value="-1">Nuevo CPE</option>
                      <option
                        v-for="cpe in cpes"
                        :key="cpe.id"
                        :value="cpe.id"
                      >
                        {{ cpe.nombre }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label>Estado</label>
                    <select
                      v-model="cpeSeleccionado.id_estado"
                      name="id_estado"
                      ref="id_estado"
                      class="form-select"
                      :disabled="contrato.id_cpe == null"
                      @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="estado in estados_cpes"
                        :value="estado.id"
                        :key="estado.id"
                      >
                        {{ estado.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Marca</label>
                    <select
                      v-model="cpeSeleccionado.id_marca"
                      @change="
                        cpeSeleccionado.id_modelo = null,
                        contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                        cargarModelosCpes()
                      "
                      ref="id_marca"
                      class="form-select"
                      :disabled="contrato.id_cpe == null"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="marca in marcas_cpes"
                        :value="marca.id"
                        :key="marca.id"
                      >
                        {{ marca.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Modelo</label>
                    <select
                      v-model="cpeSeleccionado.id_modelo"
                      ref="id_modelo"
                      class="form-select"
                      :disabled="modelos_cpes.length == 0 || contrato.id_cpe == null"
                      @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="modelo in modelos_cpes"
                        :value="modelo.id"
                        :key="modelo.id"
                      >
                        {{ modelo.identificador }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label>Nombre del CPE</label>
                    <input
                      v-model="cpeSeleccionado.nombre"
                      ref="nombre"
                      class="form-control"
                      name="nombre"
                      placeholder="Nombre del CPE"
                      :disabled="contrato.id_cpe == null"
                      @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                    />
                  </div>
                </div>
              </div>
            </div>

            <br/>
            <br/>

            <h5>Router de administración</h5>
            <hr/>

            <div class="row">
              <div class="col-md-6">
                <label>Router</label>
                <select
                  ref="id_router"
                  class="form-select"
                  v-model="contrato.id_router"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cpeSeleccionado.id_router = contrato.id_router,
                    actualizarIspsRouter(),
                    cargarServiciosPPPoE(),
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                  :disabled="contrato.id_cpe == null"
                >
                  <option :value="null">Seleccionar</option>
                  <option
                    v-for="router in routers"
                    :key="router.id"
                    :value="router.id"
                  >
                    {{ router.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label>Proveedor de internet</label>
                <select
                  class="form-select"
                  name="id_isp"
                  ref="id_isp"
                  v-model="contrato.id_isp"
                  :disabled="contrato.id_cpe == null"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cpeSeleccionado.id_isp = contrato.id_isp
                  "
                >
                  <option :value="null">Seleccionar</option>
                  <option v-for="isp in isps" :key="isp.id" :value="isp.id">
                    {{ isp.nombre }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>Conectado a sitio</label>
                <select
                  class="form-select"
                  name="id_sitio"
                  ref="id_sitio"
                  v-model="contrato.id_sitio"
                  :disabled="contrato.id_cpe == null"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cpeSeleccionado.id_sitio = contrato.id_sitio
                  "
                >
                  <option :value="null">Sitio del router</option>
                  <option
                    v-for="sitio in sitios"
                    :key="sitio.id"
                    :value="sitio.id"
                  >
                    {{ sitio.nombre }}
                  </option>
                </select>
              </div>
            </div>
            
            <div class="row" v-if="cpeSeleccionado.id_router != null">
              <div class="col-md-6">
                <h5>Puertas de enlace disponibles</h5>
                <template v-for="(puerta, index) in puertas_enlace">
                  <span class="badge font-size-15 badge-pill badge-soft-primary" v-if="true" :key="index">
                    {{ puerta }}
                  </span>
                  &nbsp;
                </template>
                <span
                  v-if="puertas_enlace.length == 0"
                  class="badge font-size-15 bg-warning bg-gradient"
                >
                  Ninguna
                </span>
              </div>
              <div class="col-md-6">
                <h5>Servicios PPPoE disponibles</h5>
                <template v-for="(servicio, index) in serviciosPPPoE">
                  <span class="badge font-size-15 badge-pill badge-soft-primary" v-if="true" :key="index">
                    {{ servicio.nombre }}
                  </span>
                  &nbsp;
                </template>
                <span
                  v-if="serviciosPPPoE.length == 0"
                  class="badge font-size-15 bg-warning bg-gradient"
                >
                  Ninguno
                </span>
              </div>
            </div>

            <br>
            <h5>Administración</h5>
            <hr>

            <div class="row">
              <div class="col-md-6">
                <label>Modo de administración</label>
                <select
                  class="form-select"
                  ref="pppoe"
                  v-model="cpeSeleccionado.pppoe"
                  :disabled="contrato.id_cpe == null"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cargarServiciosPPPoE(),
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                >
                  <option :value="0">Por dirección IP estática</option>
                  <option :value="1">Por credencial PPPoE</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>Dirección IP de WAN</label>
                <input
                  type="text"
                  v-model="cpeSeleccionado.ip_wan"
                  ref="ip_wan"
                  placeholder="172.16.1.X"
                  class="form-control"
                  v-show="cpeSeleccionado.pppoe == false"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                  :readonly="contrato.id_cpe == null"
                />
                <input
                  type="text"
                  class="form-control"
                  :value="'- - Automática - -'"
                  readonly
                  disabled
                  v-show="cpeSeleccionado.pppoe == true"
                />
              </div>
              <div class="col-md-6">
                <label>Dirección MAC de WAN</label>
                <input
                  type="text"
                  ref="mac_wan"
                  v-model="cpeSeleccionado.mac_wan"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  class="form-control"
                  maxlength="17"
                  placeholder="MAC del puerto WAN en el CPE"
                  :readonly="contrato.id_cpe == null"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>Dirección IP de LAN</label>
                <input
                  type="text"
                  ref="ip_lan"
                  v-model="cpeSeleccionado.ip_lan"
                  class="form-control"
                  placeholder="192.168.100.1"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.id_cpe == null"
                />
              </div>
              <div class="col-md-6">
                <label>Dirección MAC de LAN</label>
                <input
                  type="text"
                  v-model="cpeSeleccionado.mac_lan"
                  ref="mac_lan"
                  class="form-control"
                  maxlength="17"
                  placeholder="MAC del puerto LAN en el CPE"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.id_cpe == null"
                />
              </div>
            </div>
            <div class="row" v-show="cpeSeleccionado.pppoe == true">
              <div class="col-md-6">
                <label>Usuario PPPoE</label>
                <input
                  type="text"
                  v-model="cpeSeleccionado.usuario_pppoe"
                  ref="usuario_pppoe"
                  class="form-control"
                  placeholder="Usuario PPPoE"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.id_cpe == null"
                />
              </div>
              <div class="col-md-6">
                <label>Clave PPPoE</label>
                <input
                  type="text"
                  v-model="cpeSeleccionado.clave_pppoe"
                  ref="clave_pppoe"
                  class="form-control"
                  placeholder="Clave PPPoE"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.id_cpe == null"
                />
              </div>
            </div>

            <br>
            <h5>Credencial de administración</h5>
            <hr>
            
            <div class="row">
              <div class="col-md-6">
                <label>Usuario</label>
                <input
                  type="text"
                  placeholder="Usuario"
                  v-model="cpeSeleccionado.usuario"
                  class="form-control"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.id_cpe == null"
                />
              </div>
              <div class="col-md-6">
                <label>Clave</label>
                <input
                  type="text"
                  placeholder="Clave"
                  v-model="cpeSeleccionado.clave"
                  class="form-control"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.id_cpe == null"
                />
              </div>
            </div>

            <br>
            <h5>Control del servicio de internet</h5>
            <hr>

            <div class="row">
              <div class="col-md-6">
                <label>Control automático</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="!contrato.control_manual_cpe"
                    @change="contrato.control_manual_cpe = !contrato.control_manual_cpe"
                    class="form-check-input"
                    type="checkbox"
                    id="controlAutomatico"
                  />
                  <label class="form-check-label" for="controlAutomatico"></label>
                </div>
              </div>
              <div class="col-md-4">
                <label>Acceso a internet</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    v-model="contrato.enlace_activo_cpe"
                    class="form-check-input"
                    type="checkbox"
                    :disabled="!contrato.control_manual_cpe"
                    id="enlaceActivo"
                  />
                  <label class="form-check-label" for="enlaceActivo"></label>
                </div>
              </div>
            </div>

            <br>
            <h5>Información complementaria</h5>
            <hr>

            <div class="row">
              <div class="col-md-12">
                <label>Nota de información complementaria para el CPE</label>
                <textarea
                  v-model="cpeSeleccionado.nota"
                  class="form-control"
                  name="nota"
                  ref="nota"
                  rows="10"
                  maxlength="2000"
                  :readonly="contrato.id_cpe == null"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">DH <small>Dispositivo de hogar</small></h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      onclick="return false;"
                      v-show="!contrato.cancelado && !bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <router-link
                  :to="{
                    name: 'edicionDh',
                    params: { id: contrato.id_dh }
                  }"
                  v-if="dhSeleccionado.id != null && contrato.id_dh != null"
                >
                  <img
                    :src="`${API}/dhs/${dhSeleccionado.id}/foto?tipo_foto=150x150&_tk=${tk}`"
                    alt="foto del DH"
                    v-if="dhSeleccionado.id != null"
                    class="img-fluid img-thumbnail rounded"
                  />
                </router-link>
                <img
                  :src="
                    `${API}/dhs/modelos/${dhSeleccionado.id_modelo}/imagen?tipo_foto=150x150&_tk=${tk}`
                  "
                  alt="imagen del dh"
                  v-if="dhSeleccionado.id == null && dhSeleccionado.id_modelo != null"
                  class="img-fluid img-thumbnail rounded"
                />
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <label>Selección de DH</label>
                    <select
                      class="form-select"
                      name="id_dh"
                      v-model="contrato.id_dh"
                      @change="actualizarDhSeleccionado()"
                      :disabled="contrato.cancelado == 1"
                    >
                      <option :value="null">Seleccionar</option>
                      <option :value="-1">Nuevo DH</option>
                      <option
                        v-for="(dh, index) in dhs"
                        :key="`dh${index}`"
                        :value="dh.id"
                        v-show="
                          dh.ocupado == false || dh.id == contrato.id_dh
                        "
                      >
                        {{ dh.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Estado</label>
                    <select
                      name="id_estado"
                      class="form-select"
                      v-model="dhSeleccionado.id_estado"
                      @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                      :disabled="contrato.id_dh == null"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="estado in estados_dh"
                        :value="estado.id"
                        :key="estado.id"
                      >
                        {{ estado.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label>Tipo</label>
                    <select
                      name="id_tipo"
                      class="form-select"
                      id="imgMarca"
                      v-model="dhSeleccionado.id_tipo"
                      @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                      :disabled="contrato.id_dh == null"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="tipo in tipos_dh"
                        :key="tipo.id"
                        :value="tipo.id"
                      >
                        {{ tipo.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label>Marca</label>
                    <select
                      name="id_marca"
                      class="form-select"
                      v-model="dhSeleccionado.id_marca"
                      :disabled="contrato.id_dh == null"
                      @change="
                        dhSeleccionado.id_modelo = null,
                        contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : '',
                        cargarModelosDH()
                      "
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="marca in marcas_dh"
                        :key="marca.id"
                        :value="marca.id"
                      >
                        {{ marca.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label>Modelo</label>
                    <select
                      name="id_modelo"
                      class="form-select"
                      v-model="dhSeleccionado.id_modelo"
                      @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                      :disabled="modelos_dh.length == 0 || contrato.id_dh == null"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="modelo in modelos_dh"
                        :key="modelo.id"
                        :value="modelo.id"
                      >
                        {{ modelo.identificador }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label>Nombre</label>
                    <input
                      class="form-control"
                      name="nombre"
                      placeholder="Nombre del DH"
                      v-model="dhSeleccionado.nombre"
                      @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                      :readonly="contrato.id_dh == null"
                    />
                  </div>
                </div>
              </div>
            </div>

            <br>
            <h5>Información de red</h5>
            <hr>

            <div>
              <div class="row">
                <div class="col-md-6">
                  <label>Dirección IP de WAN</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.ip_wan"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="192.168.100.2"
                    :readonly="contrato.id_dh == null"
                  />
                </div>
                <div class="col-md-6">
                  <label>Dirección MAC de WAN</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.mac_wan"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="MAC del puerto WAN en el DH"
                    :readonly="contrato.id_dh == null"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label>Dirección IP de LAN</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.ip_lan"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="192.168.200.1"
                    :readonly="contrato.id_dh == null"
                  />
                </div>
                <div class="col-md-6">
                  <label>Dirección MAC de LAN</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.mac_lan"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="MAC del puerto LAN en el DH"
                    :readonly="contrato.id_dh == null"
                  />
                </div>
              </div>

              <br>
              <h5>Credencial de administración</h5>
              <hr>

              <div class="row">
                <div class="col-md-6">
                  <label>Usuario</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.usuario"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="Usuario"
                    :readonly="contrato.id_dh == null"
                  />
                </div>
                <div class="col-md-6">
                  <label>Clave</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.clave"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="Clave"
                    :readonly="contrato.id_dh == null"
                  />
                </div>
              </div>

              <br />
              <h5>Configuración WIFI</h5>
              <hr>
              
              <div class="row">
                <div class="col-md-6">
                  <label>SSID</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.ssid"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="SSID"
                    :readonly="contrato.id_dh == null"
                  />
                </div>
                <div class="col-md-6">
                  <label>Clave</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.clave_wifi"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="Clave WIFI"
                    :readonly="contrato.id_dh == null"
                  />
                </div>
              </div>

              <br />
              <h5>Información complementaria</h5>
              <hr>

              <div class="row">
                <div class="col-md-12">
                  <label>Nota del dispositivo</label>
                  <textarea
                    class="form-control"
                    name="nota"
                    rows="10"
                    maxlength="2000"
                    placeholder="Información complementaria"
                    v-model="dhSeleccionado.nota"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    :readonly="contrato.id_dh == null"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-show="modo == 'nuevo' && bandera_nuevo_contrato">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Ubicación del servicio</h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      onclick="return false;"
                      v-show="!contrato.cancelado && !bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="card-body">
            <button
              class="btn btn-light"
              @click="usarDireccionCliente()"
            >
              <i class="mdi mdi-directions-fork"></i>
              Usar dirección del cliente
            </button>
            <br><br>
            
            <GoogleMap
              ref="mapa"
              :api-key="apikeyGoogle"
              :center="posicionInicial"
              style="width: 100%; height: 371px;"
              :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
              :zoom="mapaZoom"
              v-if="modo == 'nuevo' && bandera_nuevo_contrato"
            >
              <Marker
                ref="marker"
                :options="{ 
                  position: posicionInicial,
                  icon: markerIcon,
                  draggable: contrato.cancelado ? false : true,
                }"
                @dragend="mostrarPosicion($event)"
              ></Marker>
            </GoogleMap>
            <!-- fin de google maps -->
            <br />
            <div class="row">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text">
                    <span class="d-none d-sm-block">
                      Latitud,Longitud
                    </span>
                    <span class="d-block d-sm-none">
                      Lat,Lng
                    </span>
                  </span>
                  <input
                    type="text"
                    class="form-control text-right"
                    v-model="lat_lng"
                    :readonly="bloquear_lat_lng"
                    :disabled="contrato.cancelado == 1"
                    @change="actualizarPosicion()"
                  />
                  <button class="btn btn-light" 
                    @click="bloquear_lat_lng = !bloquear_lat_lng"
                  >
                    <i
                      class="mdi"
                      :class="{
                        'mdi-lock-outline': bloquear_lat_lng,
                        'mdi-lock-open-variant-outline': !bloquear_lat_lng
                      }"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6" v-show="tieneAlias('Calle')">
                <label>{{cargarAliasCampo('Calle')}}</label>
                <input
                  class="form-control"
                  name="calle"
                  v-if="tieneOpciones('Calle')"
                  v-model="contrato.calle"
                  :disabled="contrato.cancelado == 1"
                />
                <select
                  class="form-select"
                  name="calle"
                  v-if="!tieneOpciones('Calle')"
                  v-model="contrato.calle"
                  :disabled="contrato.cancelado == 1"
                >
                  <option
                    v-for="(opcion,index) in cargarOpciones('Calle')"
                    :key="`opcion-calle-${index+1}`"
                  >
                    {{opcion.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-md-3" v-show="tieneAlias('N° ext')">
                <label>{{cargarAliasCampo('N° ext')}}</label>
                <input
                  class="form-control"
                  name="numero_exterior"
                  v-if="tieneOpciones('N° ext')"
                  v-model="contrato.numero_exterior"
                  :disabled="contrato.cancelado == 1"
                />
                <select
                  class="form-select"
                  name="numero_exterior"
                  v-if="!tieneOpciones('N° ext')"
                  v-model="contrato.numero_exterior"
                  :disabled="contrato.cancelado == 1"
                >
                  <option
                    v-for="(opcion,index) in cargarOpciones('N° ext')"
                    :key="`opcion-numero-exterior-${index+1}`"
                  >
                    {{opcion.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-md-3" v-show="tieneAlias('N° int')">
                <label>{{cargarAliasCampo('N° int')}}</label>
                <input
                  class="form-control"
                  name="numero_interior"
                  v-if="tieneOpciones('N° int')"
                  v-model="contrato.numero_interior"
                  :disabled="contrato.cancelado == 1"
                />
                <select
                  class="form-select"
                  name="numero_interior"
                  v-if="!tieneOpciones('N° int')"
                  v-model="contrato.numero_interior"
                  :disabled="contrato.cancelado == 1"
                >
                  <option
                    v-for="(opcion,index) in cargarOpciones('N° int')"
                    :key="`opcion-numero-interior-${index+1}`"
                  >
                    {{opcion.nombre}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" v-show="tieneAlias('Colonia')">
                <label>{{cargarAliasCampo('Colonia')}}</label>
                <input
                  class="form-control"
                  name="colonia"
                  v-if="tieneOpciones('Colonia')"
                  v-model="contrato.colonia"
                  :disabled="contrato.cancelado == 1"
                />
                <select
                  class="form-select"
                  name="colonia"
                  v-if="!tieneOpciones('Colonia')"
                  v-model="contrato.colonia"
                  :disabled="contrato.cancelado == 1"
                >
                  <option
                    v-for="(opcion,index) in cargarOpciones('Colonia')"
                    :key="`opcion-colonia-${index+1}`"
                  >
                    {{opcion.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-md-5" v-show="tieneAlias('Delegación o Municipio')">
                <label>{{cargarAliasCampo('Delegación o Municipio')}}</label>
                <input
                  class="form-control"
                  name="municipio"
                  v-if="tieneOpciones('Delegación o Municipio')"
                  v-model="contrato.municipio"
                  :disabled="contrato.cancelado == 1"
                />
                <select
                  class="form-select"
                  name="municipio"
                  v-if="!tieneOpciones('Delegación o Municipio')"
                  v-model="contrato.municipio"
                  :disabled="contrato.cancelado == 1"
                >
                  <option
                    v-for="(opcion,index) in cargarOpciones('Delegación o Municipio')"
                    :key="`opcion-minicipio-${index+1}`"
                  >
                    {{opcion.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-md-3" v-show="tieneAlias('Código postal')">
                <label>{{cargarAliasCampo('Código postal')}}</label>
                <input
                  class="form-control"
                  name="codigo_postal"
                  v-if="tieneOpciones('Código postal')"
                  v-model="contrato.codigo_postal"
                  :disabled="contrato.cancelado == 1"
                />
                <select
                  class="form-select"
                  name="codigo_postal"
                  v-if="!tieneOpciones('Código postal')"
                  v-model="contrato.codigo_postal"
                  :disabled="contrato.cancelado == 1"
                >
                  <option
                    v-for="(opcion,index) in cargarOpciones('Código postal')"
                    :key="`opcion-codigo-postal-${index+1}`"
                  >
                    {{opcion.nombre}}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4" v-show="tieneAlias('Estado de residencia')">
                <label>{{cargarAliasCampo('Estado de residencia')}}</label>
                <input
                  class="form-control"
                  name="estado_residencia"
                  v-if="tieneOpciones('Estado de residencia')"
                  v-model="contrato.estado_residencia"
                  :disabled="contrato.cancelado == 1"
                />
                <select
                  class="form-select"
                  name="estado_residencia"
                  v-if="!tieneOpciones('Estado de residencia')"
                  v-model="contrato.estado_residencia"
                  :disabled="contrato.cancelado == 1"
                >
                  <option
                    v-for="(opcion,index) in cargarOpciones('Estado de residencia')"
                    :key="`opcion-estado-residencia-${index+1}`"
                  >
                    {{opcion.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-md-3" v-show="tieneAlias('País')">
                <label>{{cargarAliasCampo('País')}}</label>
                <input
                  class="form-control"
                  name="pais"
                  v-if="tieneOpciones('País')"
                  v-model="contrato.pais"
                  :disabled="contrato.cancelado == 1"
                />
                <select
                  class="form-select"
                  name="pais"
                  v-if="!tieneOpciones('País')"
                  v-model="contrato.pais"
                  :disabled="contrato.cancelado == 1"
                >
                  <option
                    v-for="(opcion,index) in cargarOpciones('País')"
                    :key="`opcion-pais-${index+1}`"
                  >
                    {{opcion.nombre}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary" @click="atras()">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
  
        <button
          class="btn btn-success"
          @click="modo == 'nuevo' ? guardar() : actualizar()"
          :disabled="bandera_spinner"
        >
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script src="./EdicionCliente.js"></script>

<style scoped>
.mt-n5 {
  margin-top: -4.9rem !important;
}
.avatar-xl {
  height: 10rem;
  width: 10rem;
}
.prefijo {
  width: 100%;
  border: none;
  background-color: transparent;
}
.telefono {
  width: 100%;
  border: none;
  background-color: transparent;
}

.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}

.text-gris {
  color: #8C9FB9;
}
</style>